import React from "react";
import { Link, graphql } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ProductHighlight from "../components/ProductHighlight";
import ProductLinkListHorizonal from "../components/ProductLinkListHorizonal";

const Content = ({ pageContext, data, location }) => {
  //const siteTitle = data.site.siteMetadata?.title || `Title`;
  const posts = data.allMdx.nodes;

  const title = "Content Page Index";
  const description = "Discover more about Dr. Pepper.";

  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const prevPage = currentPage - 1 === 1 ? "/content/" : `/content/${(currentPage - 1).toString()}/`;
  const nextPage = `/content/${(currentPage + 1).toString()}/`;
  const firstPage = `/content/`;
  const lastPage = `/content/${numPages.toString()}/`;

  const content = (
    <ol style={{ listStyle: `none` }}>
      {posts.map((post) => {
        const title = post.frontmatter.title || post.fields.slug;

        return (
          <li key={post.fields.slug}>
            <article className="post-list-item" itemScope itemType="http://schema.org/Article">
              <header>
                <h2>
                  <Link to={post.frontmatter.slug} itemProp="url">
                    <span itemProp="headline">{title}</span>
                  </Link>
                </h2>
                <small>{post.frontmatter.date}</small>
              </header>
              <section>
                <p
                  dangerouslySetInnerHTML={{
                    __html: post.frontmatter.description || post.excerpt,
                  }}
                  itemProp="description"
                />
              </section>
            </article>
          </li>
        );
      })}
    </ol>
  );

  if (posts.length === 0) {
    return (
      <Layout>
        <Seo title={title} description={description} />
        <Container>
          <Row>
            <Col>
              <h1 className="mb-4">{title}</h1>
              <p>Sadly there are currently no content pages to display, please check back again!</p>
            </Col>
          </Row>
        </Container>
      </Layout>
    );
  }

  return (
    <Layout>
      <Seo title={title} description={description} />
      <Container className="my-3">
        <Row>
          <Col>
            <h1 className="mb-4">{title}</h1>
            {content}
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col>
            <div className="pagination" role="navigation">
              {1 < numPages && (
                <span>
                  Page {currentPage} of {numPages}
                </span>
              )}
              {!isFirst && <Link to={firstPage}>« First</Link>}
              {!isFirst && (
                <Link to={prevPage} rel="prev">
                  «
                </Link>
              )}
              {Array.from({ length: numPages }, (_, i) => (
                <Link key={`pagination-number${i + 1}`} to={`/content/${i === 0 ? "" : i + 1 + "/"}`}>
                  {i + 1}
                </Link>
              ))}
              {!isLast && (
                <Link to={nextPage} rel="next">
                  »
                </Link>
              )}
              {!isLast && (
                <Link to={lastPage} rel="next">
                  Last »
                </Link>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default Content;

export const pageQuery = graphql`
  query contentListQuery1($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(limit: $limit, skip: $skip) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
          slug
        }
      }
    }
  }
`;
